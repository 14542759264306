export const en = {
  "form.firstName": "Firstname *",
  "form.firstName.placeholder": "Max",
  "form.lastName": "Lastname *",
  "form.lastName.placeholder": "Mustermann",
  "form.dateOfBirth": "Date of birth *",
  "form.dateOfBirth.placeholder": "20.12.1980",
  "form.phone": "Mobile phonenumber *",
  "form.phone.placeholder": "+41 123 456789",
  "form.email": "Email address *",
  "form.email.placeholder": "max@mustermann.ch",
  "form.idNumber": "ID number (e.g. Passport)",
  "form.cfpCode": "CFP identificationcode",
  "title.personalData": "Personal data",
  "title.contact": "Contact",
  "title.cfp": "Covid-Free Pass (optional)",
  "form.gender": "Gender *",
  "form.gender.placeholder": "Please select",
  "form.gender.male": "male",
  "form.gender.female": "female",
  "form.gender.other": "divers",
  "form.street": "Street *",
  "form.street.placeholder": "Musterstreet 1",
  "form.city": "City *",
  "form.city.placeholder": "Mustercity",
  "form.postalCode": "Postal code *",
  "form.postalCode.placeholder": "1234",
  "form.countryCode": "Country *",
  "form.countryCode.placeholder": "Please select",
  "title.address": "Address",
  "action.reset": "Reset",
  "action.send": "Send",
  "validation.required": "Required field",
  "validation.invalidPhone":
    "Phone number has to start with '+' or '00' and the country code",
  "form.submit.success": "CFP successfully created!",
  "form.submit.error": "CFP could not be created.",
  "validation.gtc_required":
    "You have to accept the general terms & conditions.",
  "validation.privacy_required":
    "To issue a CFP you have to accept the privacy policy.",
  "form.gtc": "I agree to the {gtc}. *",
  "form.privacy":
    "I accept my data is processed as stated in the {privacyPolicy}. *",
  gtc: "GTC",
  privacyPolicy: "privacy policy",
  "form.insuranceCardNumber": "Insurance number",
  "form.insuranceCardNumber.placeholder": "80756xxxxx xxxxxxxxxx",
  "validation.invalidInsuranceCardNumberFormat":
    "Invalid format. Has to start with 80756 and must be 20 digits long.",
};
